
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { CommunityClient, PostClient } from '@/services/Services';
import NewPostModal from '@/components/newPostModal.vue';
import Post from '@/components/post.vue';
import * as OM from '@/model';
import * as CONST from '@/const';

@Options({
    components: {
        Post
    }
})
export default class Bacheca extends Vue {

    posts: OM.PostVm[] = [];
    isFiera: boolean = false;
    communityIdentifier: string = "";

    created() {
        this.init();
    }
    
    @Watch("$route.params.slug")
    async init() {
        var slug = this.$route.params.slug;

        if(!slug)
            return;

        this.isFiera = this.$route.path.indexOf("fiera") > -1;
        var community = await CommunityClient.getBySlug(this.$route.params.slug.toString())
        this.communityIdentifier = community.identifier;

        PostClient.getByParent(community.identifier)
        .then(x => {
            this.posts = x;
            if(this.$route.query.f){
                setTimeout(() => {
                    let post: any = this.$refs[<string>this.$route.query.f];
                    if(post){
                        post.$el.classList.add('evidenzia');

                        post.$el.scrollIntoView();
                        setTimeout(() => {
                            post.$el.classList.remove('evidenzia')
                        }, 2000)
                    }
                }, 200)
            }
        })
    }

    async newPost() {
        this.$opModal.show(NewPostModal, {
            containerIdentifier: this.communityIdentifier,
            postType: CONST.NotificationType.CommunityPostNew
        }, () => {
            this.init();
        });
    }

    onRemoved(postIdentifier: string, index: number) {
        this.posts.splice(index, 1);
    }

}

