
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { getUniversalISOStringDate, makeFilePreview } from '@/utils';
import { CommunityClient, PostClient } from '@/services/Services';
import ProgressBar from '@/components/progressBar.vue';
import UoseWriter from '@/components/uoseWriter.vue';
import store from '@/store';
import * as OM from '@/model';
import * as VM from '@/viewModel';

@Options({
    components: {
        ProgressBar,
        UoseWriter
    }
})
export default class NewPostModal extends Vue {

    @Prop() title: string;
    @Prop() containerIdentifier: string;
    @Prop() postType: string;

    vm: VM.CreatePostVm = new VM.CreatePostVm();
    isCordova: boolean = !!window.cordova;
    uploading: boolean = false;
    uploadProgress: number = 0;
    todayDate: string = getUniversalISOStringDate(new Date());

    created() {
        this.vm.containerIdentifier = this.containerIdentifier;
        this.vm.postType = this.postType;
    }

    doPost() {
        delete this.vm.publishedDate;
        store.state.globalSpinner++;
        PostClient.createOrUpdatePost(this.vm)
        .then(x => {
            PostClient.updatePostImage(x, this.vm.attachment)
            .then(x => {
                this.$emit('close');
            })
            .finally(() => {
                store.state.globalSpinner--;
                this.uploading = false;
            });
        })
    }

    doRec: boolean = false;
    recbuffer: string = "";
    communities: OM.SearchResultVm[] = [];
    @Watch('vm.text')
    onTextChange(next, prev) {
        if(next.length - prev.length == 1){
            let lastLetter = next[next.length - 1];
            if(lastLetter == ' '){
                this.doRec = false;
                this.recbuffer = "";
            } else {
                if(this.doRec){
                    this.recbuffer += lastLetter;
                    this.searchCommunities(this.recbuffer);
                } else {
                    if(lastLetter == '&'){
                        this.doRec = true;
                    }
                }
            }
        } else if(next.length - prev.length == -1) {
            this.recbuffer = this.recbuffer.substr(0, this.recbuffer.length - 1);
            this.searchCommunities(this.recbuffer);
        }
    }
    chatSearchCommunities: OM.SearchResultVm[] = [];
    searchCommunities(query) {
        CommunityClient.chatSearch(query)
        .then(x => {
            this.chatSearchCommunities = x;
        })
    }
    setCommunity(item: OM.SearchResultVm){
        this.communities
    }

    preview: any = null;
    @Watch('vm.attachment')
    onAttachmentChange(next, prev) {
        this.makePreview();
    }
    makePreview() {
        if(this.vm.attachment){
            if(typeof this.vm.attachment == "string"){
                this.preview = this.vm.attachment;
            } else {
                makeFilePreview(this.vm.attachment)
                .then(x => {
                    this.preview = x;
                })
            }
        }
    }

    removeAttachment() {
        this.preview = "";
        this.vm.attachment = null;
    }

}

